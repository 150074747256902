import React from "react";
import { Button } from "../buttons";
import { HeaderWrapper, CompanyLogo, ButtonsContainer } from "./styled";


import mainLogo from "../../assets/images/logo.png";


export const Header = () => {
    return (
        <HeaderWrapper id="header">
            <CompanyLogo src={mainLogo}/>
            <ButtonsContainer>
                <Button
                    goTo="#aboutCompany"
                    btnText="Şirkət haqqında"
                    right
                    background
                />
                <Button
                    goTo="#services"
                    btnText="Xidmətlərimiz"
                    right
                    background
                />
                <Button
                    goTo="#projects"
                    btnText="Layihəmiz"
                    right
                    background
                />
                <Button
                    goTo="#partners"
                    btnText="Partnyolarimiz"
                    right
                    background
                />
            </ButtonsContainer>
        </HeaderWrapper>
    );
};
