import styled from "styled-components";
import mainPageBackground from '../../assets/images/mainpageBackground.png'

export const MainPageWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  background-image: url(${mainPageBackground});
  background-repeat: no-repeat;
  background-size:  cover;
  justify-content: center;

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const MainText = styled.div`
  @font-face {
    font-family: Arimo;
    src: url("../../assets/fonts/Arimo/arimo.woff");
  }
  display: flex;
  color: white;
  font-size: 96px;
  
  @media (max-width: 1680px) {
    font-size: 96px;
  }
  @media (max-width: 1440px) {
    font-size: 96px;
  }
  @media (max-width: 1280px) {
    font-size: 96px;
  }
  @media (max-width: 1000px) {
    font-size: 96px;
  }
  @media (max-width: 720px) {
    font-size: 96px;
  }
  @media (max-width: 480px) {
    font-size: 46px;
  }
`;
