import React from "react";
import DellLogo from '../../assets/images/Dell.png';
import CiscoLogo from '../../assets/images/Cisco.png';
import FortinetLogo from '../../assets/images/Fortinet.png';
import HPLogo from '../../assets/images/HP.png';
import IBMLogo from '../../assets/images/IBM.png';
import LenovoLogo from '../../assets/images/Lenovo.png';
import MicrosoftLogo from '../../assets/images/Microsoft.png';
import OracleLogo from '../../assets/images/Oracle.png';

import {
    PartnersWrapper,
    PartnersFirstPart,
    PartnersImage,
    PartnersImageAndText,
    PartnersSecondPart,
} from "./styled";

import "./styled";


export const Partners = () => {
    return (
        <PartnersWrapper id="partners">
            <PartnersFirstPart>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.dell.com/' target="_blank"
                    >
                        <PartnersImage src={DellLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.cisco.com/' target='_blank'
                    >
                        <PartnersImage src={CiscoLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.fortinet.com/' target='_blank'
                    >
                        <PartnersImage src={FortinetLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText padd>
                    <a
                        rel="noreferrer"
                        href='https://www.hp.com/us-en/home.html'
                        
                        target='_blank'>
                        <PartnersImage width src={HPLogo}/>
                    </a>
                </PartnersImageAndText>
            </PartnersFirstPart>
            <PartnersSecondPart>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.ibm.com/us-en/' target='_blank'
                    >
                        <PartnersImage src={IBMLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.lenovo.com/us/en/' target='_blank'
                    >
                        <PartnersImage src={LenovoLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.oracle.com/' target='_blank'
                    >
                        <PartnersImage src={OracleLogo}/>
                    </a>
                </PartnersImageAndText>
                <PartnersImageAndText>
                    <a
                        rel="noreferrer"
                        href='https://www.microsoft.com/en-us/' target='_blank'
                    >
                        <PartnersImage src={MicrosoftLogo}/>
                    </a>
                </PartnersImageAndText>
            </PartnersSecondPart>
        </PartnersWrapper>
    );
};
