import React from "react";
import backgroundLogo from '../../assets/images/backgroundLogo.png'
import { AboutBoldText, AboutImage, AboutPage, AboutText } from "./styled";

import './styled'


export const About = () => {
    return (
        <AboutPage id='aboutCompany'>
            <AboutText>
                <AboutBoldText>Life Electronics</AboutBoldText> Life Elektroniks
                şirkəti mənsub olduğu fəaliyyət sahəsində Azərbaycan bazarında
                qabaqcıl şirkətlərdən biridir. Şirkətin fəaliyyəti iki əsas
                istiqamətə ayrılır: informasiya texnologiyaları və təhlükəsizlik
                xidmətləri. Yarandığı vaxtdan bəri 7 il ərzində şirkət özünü
                Azərbaycanın dinamik şəkildə inkişaf edən informasiya
                texnologiyaları bazarında etibarlı tərəfdaş və sistemlərin
                inteqrasiya olunması, avadanlıqların tədarük edilməsi və
                quraşdırılması, eləcə də proqram təminatı üzrə konsaltinq
                xidmətlərini əhatə edən geniş çeşiddə xidmətlərin təchizatçısı
                kimi tanıtmışdır. Şirkət təhlükəsizliyin təmin edilməsi ilə
                bağlı xidmətlər üzrə videomüşahidə kimi sahələrdə
                ixtisaslaşmışdır. Biz öz çözümlərimizdə ən müasir və qabaqcıl
                texnologiyalardan istifadə edirik. Sifarişçinin tələblərindən
                asılı olaraq konsaltinq xidmətlərimiz texniki-iqtisadi
                əsaslandırma və digər əlavə xidmət növləri kimi seçimləri əhatə
                edə bilir. Öz fəaliyyətimizdə biz təhlükəsizlik məsələlərinə
                xüsusi önəm veririk və sifarişçi üçün ən əlverişli və münasib
                çözümlər təmin etməyi qarşımıza bir vəzifə kimi qoyuruq. Life
                Elektroniks şirkəti Telekommunikasiyalar, Neft və Qaz sənayesi,
                Dövlət, Müdafiə, Maliyyə və s. sektorları kimi bazarın müxtəlif
                sahələrində xidmətlər təklif edir və sifarişçilərin hamısını
                etibarlı kommunikasiya infrastrukturu ilə təmin edir. Xidmət
                mərkəzimiz informasiya texnologiyaları sahəsində geniş çeşiddə
                xidmətlər təklif edir.
            </AboutText>
            <AboutImage src={backgroundLogo}/>
        </AboutPage>
    );
};
