import styled from "styled-components";


export const ProjectsWrapper = styled.div`
  display: flex;
  background-color: #f1f3f6;
  justify-content: space-evenly;
  align-items: center;

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const ProjectsTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  font-size: 20px;
  
  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const ProjectTitle = styled.p`
  font-size: 25px;
  font-weight: 900;
  
  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const MarafonImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;
