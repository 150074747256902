import React, { useState } from "react";
import service from "../../assets/images/service.png";
import programming from "../../assets/images/programming.png";
import consulting from "../../assets/images/consulting.png";
import { Button } from "../buttons";
import {
    ServiceWrapper,
    ServiceCard,
    ServiceCardTitle,
    ServiceImg,
    ServiceCardText,
    ServiceCardTextWrapper
} from "./styled";



export const Service = () => {
    
    const [isOpenItService, setIsOpenItService] = useState(false);
    const [isOpenProgramming, setIsOpenProgramming] = useState(false);
    const [isOpenConsulting, setIsOpenConsulting] = useState(false);
    
    const openingItService = () => {
        if (!isOpenItService) {
            setIsOpenItService(!isOpenItService)
            setIsOpenProgramming(false)
            setIsOpenConsulting(false)
        }
    }
    
    const openingProgramming = () => {
        if (!isOpenProgramming) {
            setIsOpenProgramming(!isOpenProgramming)
            setIsOpenItService(false)
            setIsOpenConsulting(false)
        }
    }
    
    const openingConsulting = () => {
        if (!isOpenConsulting) {
            setIsOpenConsulting(!isOpenConsulting)
            setIsOpenItService(false)
            setIsOpenProgramming(false)
        }
    }
    
    return (
        <ServiceWrapper id="services">
            <ServiceCard isOpen={isOpenItService && !isOpenProgramming &&
                !isOpenConsulting}>
                {isOpenItService ?
                 <ServiceCardTextWrapper padding>
                     <ServiceCardText>
                         Life Elektroniks şirkətinin server, şəbəkə,
                         təhlükəsizlik və video müşahidə avadanlığlarının
                         quraşdırılmasında yüksək ixtisaslı mütəxəssisləri
                         məşğul olur.
                         Kommutasiya avadanlığının yaradılması və
                         çatdırılmasından başlayaraq, quraşdırılması və
                         təlimatlandırılmasına qədər, müxtəlif multiservis
                         şəbəkələrinin qurulması
                         Müxtəlif çətinlik dərəcəli məlumatların ötürülməsi
                         üçün, şəbəkələrin qurulması layihələrinin həyata
                         keçirilməsi üzrə çoxillik təcrübə
                         Həm şəxsi həllərin və məhsulların, həm də vendor
                         əsasında kənar istehsalçılardan alınan avadanlıqların
                         inteqrasiyası
                         Layihələrin həyata keçirilməsində yüksək təcrübəli
                         mütəxəssislərin iştirakı
                     </ServiceCardText>
                     <Button
                         btnText="Bağla" border color top hoverColor
                         onClick={() => setIsOpenItService(!isOpenItService)}
                     />
                 </ServiceCardTextWrapper>
                                 :
                 <ServiceCardTextWrapper>
                     <ServiceImg src={service}/>
                     <ServiceCardTitle>
                         IT Servis
                     </ServiceCardTitle>
                     <Button
                         btnText="Әlavә" border color top hoverColor
                         onClick={openingItService}
                     />
                 </ServiceCardTextWrapper>
                }
            </ServiceCard>
            
            <ServiceCard isOpen={isOpenProgramming && !isOpenItService &&
                !isOpenConsulting}>
                {
                    isOpenProgramming ?
                    <ServiceCardTextWrapper padding>
                        <ServiceCardText>
                            Life Elektroniks şirkəti rəqəmsal mühitdə korporativ
                            və tanıtım saytlarından başlayaraq ağıryüklü veb
                            xidmətləri və tələbinizə uyğun yeni proqram
                            təminatının yaradılması üçün bütün imkanları və
                            texnologiyaları özündə birləşdirir. Biz effektiv veb
                            saytları, lendinq səhifələri, funksional veb
                            xidmətlərdən CRM/ERP sistemlərinə qədər
                            hazırlayırıq. Müxtəlif növ biznes və startaplar üçün
                            saytların, xidmətlərin və mobil tətbiqlərin
                            inkişafı, dizaynı və yaradılması işlərini görürük.
                            Veb saytların yaradılması ilə məşğuluq: tanıtım
                            səhifələrindən tutmuş genişmiqyaslı kompleks
                            layihələrə qədər. Biz hər saytın xüsusi olduğuna
                            inanırıq və bunu sübut etməyə hazırıq.
                        </ServiceCardText>
                        <Button
                            btnText="Bağla" border color top hoverColor
                            onClick={
                                () => setIsOpenProgramming(
                                    !isOpenProgramming &&
                                    isOpenItService &&
                                    isOpenConsulting)}
                        />
                    </ServiceCardTextWrapper>
                                      :
                    <ServiceCardTextWrapper>
                        <ServiceImg src={programming}/>
                        <ServiceCardTitle>
                            Proqramlaşdırma
                        </ServiceCardTitle>
                        <Button btnText="Әlavә" border color top hoverColor
                                onClick={openingProgramming}/>
                    </ServiceCardTextWrapper>
                }
            </ServiceCard>
            
            <ServiceCard isOpen={isOpenConsulting && !isOpenItService &&
                !isOpenProgramming}>
                {
                    isOpenConsulting ?
                    <ServiceCardTextWrapper padding>
                        <ServiceCardText>
                            Life Elektroniks şirkəti olaraq server sistemləri,
                            Şəbəkə avadanlığı, Məlumatların saxlanılması
                            sistemləri, CCTV Kameralar, Kompüterlər,
                            Notebooklar, Printerlər və bütün növ katriclərin
                            dolumu, printerlərə yüksək xidmətin göstərilməsi
                            üzrə məhsullarımız mövcuddur.
                        </ServiceCardText>
                        <Button
                            btnText="Bağla"
                            border color top hoverColor
                            onClick={
                                () => setIsOpenConsulting(
                                    !isOpenConsulting &&
                                    isOpenProgramming &&
                                    isOpenItService)}
                        />
                    </ServiceCardTextWrapper>
                                     :
                    <ServiceCardTextWrapper>
                        <ServiceImg src={consulting}/>
                        <ServiceCardTitle>
                            Məhsullar
                        </ServiceCardTitle>
                        <Button
                            btnText="Әlavә"
                            border
                            display
                            color
                            top
                            hoverColor
                            onClick={openingConsulting}
                        />
                    </ServiceCardTextWrapper>
                }
            
            </ServiceCard>
        </ServiceWrapper>
    );
};
