import styled from "styled-components";
import arrow from "../../assets/images/arrow.png";


export const BottomButtonWrapper = styled.a`
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 70px;
  height: 70px;
  margin-right: 20px;
  background-image: url(${arrow});
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70px;
  transition: .7s;
  border: none;
  border-radius: 500px;
  cursor: pointer;

  :hover {
    box-shadow: 0 0 15px #265979;
  }

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }

`;
