import React from "react";
import { Button } from "../buttons";
import { MainPageWrapper, MainText } from "./styled";
import "./styled";
import { Header } from "../header";


export const MainPage = () => {
    return (
        <MainPageWrapper id="mainPage">
            <Header/>
            <div style={{ marginLeft: '80px' }}>
                <MainText>
                    Life Electronics
                </MainText>
                <Button
                    goTo="#aboutCompany"
                    background
                    btnText="Әlavә mәlumat"
                />
            </div>
        </MainPageWrapper>
    );
};
