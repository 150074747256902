import React from "react";
import { BtnURL, ButtonWrapper, ButtonText } from "./styled";
import "./styled";


export const Button = (props) => {
    const {
        btnText,
        goTo,
        color,
        border,
        left,
        right,
        top,
        background,
        hoverColor,
        onClick,
    } = props;
    
    return (
        <ButtonWrapper right={right} onClick={onClick}>
            <BtnURL href={goTo}>
                <ButtonText
                    color={color}
                    border={border}
                    left={left}
                    top={top}
                    background={background}
                    hoverColor={hoverColor}
                >
                    {btnText}
                </ButtonText>
            </BtnURL>
        </ButtonWrapper>
    );
};
