import styled from "styled-components";

export const AboutPage = styled.div`
  display: flex;
  height: 100vh;
  background: url("../../assets/images/aboutBackgroung.png") no-repeat;
  background-size: cover;

  @media (max-width: 1680px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }
  @media (max-width: 1440px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }
  @media (max-width: 1280px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }
  @media (max-width: 1000px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }
  @media (max-width: 720px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }
  @media (max-width: 480px) {
    background: url("../../assets/images/aboutBackgroung.png") no-repeat;
    background-size: cover;
  }

`;

export const AboutText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  color: black;
  padding-left: 60px;
  font-size: 1.7rem;

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const AboutBoldText = styled.p`
  font-weight: 900;

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;

export const AboutImage = styled.img`

  @media (max-width: 1680px) {

  }
  @media (max-width: 1440px) {

  }
  @media (max-width: 1280px) {

  }
  @media (max-width: 1000px) {

  }
  @media (max-width: 720px) {

  }
  @media (max-width: 480px) {

  }
`;
